import React from 'react';

export default function ConnectSection() {
  return (
    <div
      className="connect-section wow fadeInDown lazy1"
      style={{
        visibility: 'visible',
        animationName: 'fadeInDown',
        backgroundImage: "url('https://s3.amazonaws.com/s3-biz4intellia/images/connect-bg3.jpg')",
      }}>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <div className="connect-text">
              <h3>Enhancing Industrial Performance with Real-Time Data Insights</h3>
              <p>
                Empower your operations with MST Control's advanced data monitoring solutions. Our system provides
                real-time insights and analytics to optimize machine performance and efficiency across various
                industries.
              </p>
              <ul>
                <li>
                  <div className="connect-icon pull-left">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/new-oem-icon1.png"
                      alt="Differentiate Product"
                    />
                  </div>
                  <div className="connect-content pull-right">
                    <h5>Differentiate Product</h5>
                    <p>Get an edge over competitors with IoT implemented products.</p>
                  </div>
                </li>
                <li>
                  <div className="connect-icon pull-left">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/new-oem-icon2.png"
                      alt="Increase service-level"
                    />
                  </div>
                  <div className="connect-content pull-right">
                    <h5>Increase service-level</h5>
                    <p>Increase the after-sale service level by manufacturing IoT enabled products.</p>
                  </div>
                </li>
                <li>
                  <div className="connect-icon pull-left">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/new-oem-icon3.png"
                      alt="Reduce downtime"
                    />
                  </div>
                  <div className="connect-content pull-right">
                    <h5>Reduce downtime</h5>
                    <p>Reduce your production downtime with predictive maintenance.</p>
                  </div>
                </li>
                <li>
                  <div className="connect-icon pull-left">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/new-oem-icon4.png"
                      alt="Optimize efficiency"
                    />
                  </div>
                  <div className="connect-content pull-right">
                    <h5>Optimize efficiency</h5>
                    <p>Automate the processes and Increase profitability by reducing operational cost.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
