import React from 'react';

export default function WordDetails() {
  return (
    <div
      className="work-bg wow fadeInDown lazy1"
      id="architecture"
      style={{
        visibility: 'visible',
        animationName: 'fadeInDown',
        backgroundImage: "url('https://s3.amazonaws.com/s3-biz4intellia/images/work-bg.jpg')",
      }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h1>Data Processing and Analytics Framework</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-3 col-xs-3">
            <div
              className="work-detail wow fadeInLeft"
              data-wow-delay="0.2s"
              style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft' }}>
              <h6>
                Data Collection
                <br />
                Points
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <div
              className="work-detail wow fadeInLeft"
              data-wow-delay="0.4s"
              style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInLeft' }}>
              <h6>Data Processing Servers</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <div
              className="work-detail wow fadeInLeft"
              data-wow-delay="0.6s"
              style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInLeft' }}>
              <h6>
                Analytics Engine
                <br />
                Hub
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <div
              className="work-detail last-one wow fadeInLeft"
              data-wow-delay="0.8s"
              style={{ visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInLeft' }}>
              <h6>
                User <br />
                Dashboards
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="work-img"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
