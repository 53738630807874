import React, { useState } from 'react';
import 'styled-components/macro';
import axios from 'axios';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import Field from 'components/molecules/Field';
import PhoneInput from 'components/atoms/PhoneInput';
import Button from 'components/atoms/Button';

export default function ContactUs({ onClose }) {
  const [state, setState] = useState();
  const [form] = useForm();
  const onSubmit = async () => {
    let obj = {
      name: state.name,
      phone_number: state.phone_number,
      email: state.email,
      info: state.info,
      company: state.company,
    };
    axios
      .post(
        `${
          window.location.href.includes('.co.uk')
            ? process.env.REACT_APP_ADMIN_API.replace('.com', '.co.uk')
            : process.env.REACT_APP_ADMIN_API
        }/enquiry`,
        obj,
      )
      .then(res => {
        Toast({
          type: 'success',
          message: 'Your request has been submitted successfully',
        });
        onClose();
      })
      .catch(err => {
        Toast({
          type: 'error',
          message: err.message,
        });
      });
  };

  return (
    <>
      <p>
        Harness the power of data with MST Control. Drive tangible business results through innovative data analysis and
        insights.
      </p>

      <div>Connect with us today!</div>
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Form.Item
          label="Name"
          type="text"
          placeholder="Enter Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: 'Please enter your phone number',
            },
          ]}>
          <PhoneInput />
        </Form.Item>
        <Form.Item
          placeholder="Enter Email Id"
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Company"
          placeholder="Company Name"
          name="company"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          label="Notes"
          type="textarea"
          placeholder="Please share anything that will help prepare for our meeting."
          name="info"
          rows="4">
          <Field />
        </Form.Item>
        <div
          css={`
            display: flex;
            justify-content: center;
            gap: 20px;
          `}>
          <Button
            css={`
              background-color: #39c;
              margin-bottom: 20px;
            `}
            htmlType="submit">
            Request a Callback
          </Button>
          <Button
            css={`
              background-color: #c43c12;
              margin-bottom: 20px;
            `}
            onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Form>
      <h5
        style={{
          color: 'black',
        }}>
        Facing trouble in submitting the form? Simply mail us at{' '}
        <a style={{ color: 'blue' }} href="mailto:hello@mstcontrol.com">
          hello@mstcontrol.com
        </a>
      </h5>
    </>
  );
}
