import React from 'react';

export default function Services() {
  const services = [
    {
      heading: 'Real-Time Data Monitoring',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon1.png',
      description:
        'Live tracking and monitoring of machine operations. Ensures optimal performance of equipment. Immediate response capabilities to anomalies.',
    },
    {
      heading: 'Data Integration Services',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle2.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon2.png',
      description:
        'Seamless integration of data across platforms. Enhanced decision-making through centralized data access. Streamlined data processing and analysis.',
    },
    {
      heading: 'Performance Analytics',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle3.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon3.png',
      description:
        'Detailed performance reporting. Optimization strategies for efficiency. Real-time analytics for operational improvement.',
    },
    {
      heading: 'Historical Data Analysis',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle4.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon4.png',
      description:
        'Analysis of past data to predict future trends. Identification of patterns for strategic planning. Long-term data retention for retrospective assessment.',
    },
    {
      heading: 'IoT Device Configuration',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle5.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon5.png',
      description:
        'Configuration of IoT devices to meet operational demands. Custom setup for various industrial applications. Ongoing device management and support.',
    },
    {
      heading: 'Custom Dashboard Development',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle6.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon6.png',
      description:
        'Design of user-specific dashboards. Development of metrics and KPIs for monitoring. Integration with existing systems for consolidated views.',
    },
    {
      heading: 'Machine Performance Optimization',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle7.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon7.png',
      description:
        'Tailored strategies to boost machine efficiency. Reduction in downtime through proactive measures. Enhanced productivity and operational throughput.',
    },
    {
      heading: 'Secure Data Storage Solutions',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle8.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon8.png',
      description:
        'Secure storage solutions for sensitive data. Compliance with industry-leading security standards. Robust protection against data breaches and losses.',
    },
    {
      heading: 'Shift-End Email Reports',
      circle: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-services-circle9.png',
      icon: 'https://s3.amazonaws.com/s3-biz4intellia/images/new-service-icon9.png',
      description:
        'Automated email reports at the end of each shift. Customizable reports to monitor specific metrics. Detailed insights to enhance decision-making.',
    },
  ];

  return (
    <div
      className="services wow fadeInDown"
      id="ourServices"
      style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h3>MST Control Services</h3>
            </div>
            <div className="new-services-section desktop-section">
              <ul className="nav nav-tabs">
                {services.map((service, index) => (
                  <li key={index} className={index === 0 ? 'active' : ''}>
                    <a data-toggle="tab" href={`#service${index + 1}`} className={`tab${index + 1}`}>
                      <div className="new-services-circle">
                        <img className="lazy1" src={service.circle} alt="" />
                        <div className="new-services-circle-bg">
                          <img className="lazy1" src={service.icon} alt="" />
                        </div>
                      </div>
                      <p>{service.heading}</p>
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tab-content">
                <div className="new-service-center-m">
                  <div id="earth"></div>
                  <img
                    className="lazy1"
                    src="https://s3.amazonaws.com/s3-biz4intellia/images/service-center-img.png"
                    alt=""
                  />
                </div>
                {services.map((service, index) => (
                  <div
                    key={index}
                    id={`service${index + 1}`}
                    className={`tab-pane fade ${index === 0 ? 'in active' : ''}`}>
                    <h5>{service.heading}</h5>
                    <small>{service.description}</small>
                  </div>
                ))}
              </div>
            </div>
            <div className="new-services-section mobile-section">
              <ul className="nav nav-tabs">
                {services.map((service, index) => (
                  <li key={index}>
                    <a data-toggle="tab" href={`#service${index + 1}`} className={`tab${index + 1}`}>
                      <div className="new-services-circle">
                        <img className="lazy1" src={service.circle} alt="" />
                        <div className="new-services-circle-bg">
                          <img className="lazy1" src={service.icon} alt="" />
                        </div>
                      </div>
                      <p>{service.heading}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
