import React from 'react';

export default function WhyChooseUs() {
  return (
    <div
      className="why-choose-us wow fadeInDown lazy1"
      style={{
        visibility: 'visible',
        animationName: 'fadeInDown',
        backgroundImage: "url('https://s3.amazonaws.com/s3-biz4intellia/images/choose-bg2.jpg')",
      }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h3>why MST Control</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/iot.png"
                alt="iot-solution-img"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/iot-white.png"
                alt="iot-solution-img-hover"
                className="choose-text-hover"
              />
              <h2>
                Advanced <span> IoT Monitoring </span>
              </h2>
              <small>
                MST Control provides state-of-the-art IoT solutions for continuous monitoring and management of
                industrial machines. Our Go language servers efficiently process raw data into actionable insights,
                enabling precise calculation of production metrics and machine utilization.
              </small>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/vertical business.png"
                alt="domain-solution"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/vertical-white.png"
                alt="domain-solution-hover"
                className="choose-text-hover"
              />
              <h6>Real-Time & Historical Data Analysis</h6>
              <small>
                Explore comprehensive dashboards that provide real-time data and historical analysis. Users can view
                live updates on machine performance, detect stoppages as they happen, or delve into historical data for
                daily, weekly, or custom period reviews to track long-term trends and performance.
              </small>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/fast-time.png"
                alt="fast-time-market"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/fast-time-white.png"
                alt="fast-time-market-hover"
                className="choose-text-hover"
              />
              <h6>Detailed Shift Analysis</h6>
              <small>
                MST Control's dashboards allow for detailed shift-specific data analysis, including the ability to
                review each shift independently. Gain insights into shift timings, production counts, and performance
                metrics to optimize operational efficiency and productivity.
              </small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/module.png"
                alt="flexibility-image"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/white-module.png"
                alt="flexibility-image-hover"
                className="choose-text-hover"
              />
              <h6>
                Modular, flexible, scalable <span> &amp; standardized </span>
              </h6>
              <small>
                Leverage maximum flexibility , scalability and security with our modular architecture, repeatable
                processes, and skilled resources.
              </small>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/gradual-capability.png"
                alt="gradual-capability"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/gradual-white.png"
                alt="gradual-capability-hover"
                className="choose-text-hover"
              />
              <h6>
                Gradual capability <span> build-up</span>
              </h6>
              <small>
                All projects and engagements contribute to the target goal and supporting strategies, even if somewhat
                off-topic.
              </small>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="choose-m">
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/learning-automation.png"
                alt="learning-automation"
                className="choose-text"
              />
              <img
                src="https://s3.amazonaws.com/s3-biz4intellia/images/learning-white.png"
                alt="learning-automation-hover"
                className="choose-text-hover"
              />
              <h6>Learning and automated data pattern recognition</h6>
              <small>
                Built-in domain specific learning and automated data pattern recognition systems that are tailored to
                meet individual business needs.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
