import React, { useEffect, useState } from 'react';
import { TimeHolder } from './DateModal.styles';
import { generateTimeSlots } from 'helpers/common';
import Skeletonn from 'components/organisms/skeleton';

const TimeFilters = ({
  selectDate,
  selectedTimezone: timeZone,
  onSelectedTimeChange,
  onTimeFiltersSelect,
  slotsData = {},
  isSkeleton,
}) => {
  const [selectedIndex, setSelectedIndex] = useState({
    startTime: '',
  });

  const generateAndSortTimeSlots = () => {
    const generatedTime = generateTimeSlots(selectDate, slotsData, timeZone);
    console.log('generatedTime', generatedTime);
    return generatedTime.sort((a, b) => {
      return new Date(`1970/01/01 ${a.startTime}`) - new Date(`1970/01/01 ${b.startTime}`);
    });
  };
  const time = generateAndSortTimeSlots();

  useEffect(() => {
    setSelectedIndex({
      startTime: '',
    });
    onSelectedTimeChange({});
    onTimeFiltersSelect(false);
  }, [timeZone, selectDate]);

  const handleTimeClick = timeSlot => {
    setSelectedIndex(timeSlot);
    onSelectedTimeChange(timeSlot);
    onTimeFiltersSelect(true);
  };

  const formatDate = date => {
    return date.toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    });
  };

  // console.log('time start', time);
  // console.log('time end', timeSlot.endTime);

  return (
    <TimeHolder>
      <h1>{selectDate ? formatDate(selectDate) : 'Select a date'}</h1>
      <ul className="time-slots">
        {isSkeleton ? (
          Array.from({ length: 7 }).map((elem, ind) => <Skeletonn resp height="32" width="130" key={ind} />)
        ) : time && time.length > 0 ? (
          <>
            {time.map((timeSlot, index) => (
              <li
                key={index}
                onClick={() => handleTimeClick(timeSlot)}
                className={`time-slot ${timeSlot.startTime === selectedIndex.startTime ? 'selected' : ''} ${
                  timeSlot.isBooked ? 'booked' : ''
                }`}>
                {`${timeSlot.startTime} - ${timeSlot.endTime}`}
              </li>
            ))}
          </>
        ) : (
          <>
            <span>No slots Available!!!</span>
          </>
        )}
      </ul>
    </TimeHolder>
  );
};

export default TimeFilters;
