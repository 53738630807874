import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import { BsClock } from 'react-icons/bs';
import { GoDeviceCameraVideo } from 'react-icons/go';
import { DateHolder } from './DateModal.styles';
import TimeFilters from './TimeFilters';
import TimezoneList from './TimezoneList';
import axios from 'axios';
import { getFormattedDate } from 'helpers/common';
import { convertToZone } from 'helpers/common';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Field from 'components/molecules/Field';
import Form from 'components/molecules/Form/Form';
import { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import Button from 'components/atoms/Button';
import PhoneInput from 'components/atoms/PhoneInput';
import 'styled-components/macro';

const DateModal = ({ onClose, type }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [selectdate, setSelectDate] = useState(new Date());
  const [showCalendarForm, setShowCalendarForm] = useState(false);
  const [timeFiltersSelected, setTimeFiltersSelected] = useState(false);
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [slotsData, setSlots] = useState({
    availableSlots: [],
    bookedSlots: [],
  });
  const [{ startTime, endTime }, setTime] = useState({
    startTime: '',
    endTime: '',
  });
  const [form] = useForm();
  const handleNextClick = () => {
    if (startTime && endTime) {
      setShowCalendarForm(true);
    } else {
      Toast({
        type: 'error',
        message: 'Please select a time slot',
      });
    }
  };

  const handleSelect = date => {
    setSelectDate(date);
  };
  const postBookingData = async payload => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${
          window.location.href.includes('.co.uk')
            ? process.env.REACT_APP_ADMIN_API.replace('.com', '.co.uk')
            : process.env.REACT_APP_ADMIN_API
        }/bookedtimeslots`,

        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data && response.status === 200) {
        Toast({
          type: 'success',
          message: 'Thank you for considering us for your project. Our solution experts will get back to you shortly.',
        });
        setIsLoading(false);
        onClose();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: 'An error occurred while submitting the form.',
      });
      setIsLoading(false);
    }
  };
  const onSubmit = async values => {
    const payload = {
      date: getFormattedDate(selectdate),
      startTime: convertToZone(startTime, selectedTimezone),
      endTime: convertToZone(endTime, selectedTimezone),
      clientEmail: values.email,
      clientTimeZone: selectedTimezone,
      name: values.name,
      guestEmails: values.guestEmails,
      info: values.description,
      inquiryType: type,
    };
    await postBookingData(payload);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsSkeleton(true);

    axios
      .get(
        `${
          window.location.href.includes('.co.uk')
            ? process.env.REACT_APP_ADMIN_API.replace('.com', '.co.uk')
            : process.env.REACT_APP_ADMIN_API
        }/bookedtimeslots`,
      )
      .then(res => {
        setSlots({
          availableSlots: res.data.defaultAvailableSlots,
          bookedSlots: res.data.slotsTaken,
        });
        setIsSkeleton(false);
      })
      .catch(err => {
        setIsSkeleton(false);
      });
  }, []);
  const handleSelectedTimeChange = time => {
    setTime(time);
  };

  return (
    <DateHolder>
      <div className="Content">
        <div className="image-holder">MST CONTROL</div>
        <div className="company-content">
          <h4>Schedule a Consultation Meeting With Our Tech Specialists</h4>
          <ul>
            <li>
              <BsClock size="20px" />
              30m
            </li>
            <li>
              <GoDeviceCameraVideo size="30px" />
              Web conferencing details provided upon confirmation.
            </li>
          </ul>
          <small>We are here to listen and help you bring your vision to life.</small>
        </div>
      </div>
      {!showCalendarForm && (
        <div className="Calendar">
          <h2>Select a Date & Time</h2>
          <div className="calendar-body">
            <div className="custom-calendar">
              <Calendar
                date={selectdate}
                onChange={handleSelect}
                minDate={new Date()}
                showMonthAndYearPickers={false}
                className="custom-calendar-inner"
              />
              <TimeFilters
                isSkeleton={isSkeleton}
                slotsData={slotsData}
                selectedTimezone={selectedTimezone}
                selectDate={selectdate}
                onTimeFiltersSelect={() => setTimeFiltersSelected(true)}
                onSelectedTimeChange={handleSelectedTimeChange}
              />
            </div>
            <TimezoneList selectedTimezone={selectedTimezone} onTimezoneChange={setSelectedTimezone} />
          </div>
          <Button
            type="primary"
            loading={isLoading}
            shadowH="none"
            width="165"
            minWidth="165"
            height="40"
            size="24"
            minsize="16"
            weight="500"
            radius="9px"
            htmlType="submit"
            onClick={handleNextClick}
            disabled={!timeFiltersSelected}
            style={{
              marginTop: '20px',
              width: '165px',
            }}>
            Next
          </Button>
        </div>
      )}
      {showCalendarForm && (
        <div className="CalendarForm">
          <>
            <h3
              css={`
                margin-top: 20px;
              `}>
              <IoIosArrowRoundBack
                size="24px"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  setShowCalendarForm(false);
                }}
              />
              We Cannot Wait to Hear about your Idea!
            </h3>
            <Form
              css={`
                padding: 20px;
              `}
              form={form}
              onSubmit={onSubmit}
              onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
              <Form.Item
                label="Name"
                type="text"
                placeholder="Enter Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name',
                  },
                ]}>
                <Field />
              </Form.Item>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number',
                  },
                ]}>
                <PhoneInput />
              </Form.Item>
              <Form.Item
                placeholder="Enter Email Id"
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                ]}>
                <Field />
              </Form.Item>
              <Form.Item
                type="text"
                label="Company"
                placeholder="Company Name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name',
                  },
                ]}>
                <Field />
              </Form.Item>
              <Form.Item
                label="Notes"
                type="textarea"
                placeholder="Please share anything that will help prepare for our meeting."
                name="info"
                rows="4">
                <Field />
              </Form.Item>
              <div
                css={`
                  display: flex;
                  justify-content: center;
                  gap: 20px;
                `}>
                <Button
                  css={`
                    background-color: #39c;
                    margin-bottom: 20px;
                  `}
                  htmlType="submit">
                  Request a Callback
                </Button>
                <Button
                  css={`
                    background-color: #c43c12;
                    margin-bottom: 20px;
                  `}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        </div>
      )}
    </DateHolder>
  );
};

export default DateModal;
