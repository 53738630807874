/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import mst_controll from 'assets/images/mst_controll.png';
import { useContextHook } from 'use-context-hook';
import { LoadingContext } from 'context/loadingContext';
import ModalContainer from 'components/molecules/ModalContainer';
import ContactUs from '../contact-us';
import 'styled-components/macro';
import Select from 'components/atoms/Select';
import { HeaderContainer, Logo, DemoButton, Title, NavBar, NavWrapper } from './header.styles';
const HeaderComponent = ({ title = 'Take Total Control Of Your Production', showDemo = false }) => {
  const { lang, setLang } = useContextHook(LoadingContext, {
    lang: 1,
    setLang: 1,
  });
  return (
    <HeaderContainer>
      <NavBar>
        <Logo
          onClick={() => {
            window.location.href = '/';
          }}>
          <img src={mst_controll} alt="MST Logo" />
        </Logo>
        <NavWrapper>
          <Select
            css={`
              margin-bottom: -20px;
              background-color: transparent;
              background: none !important;
              border: none;
              color: #0f0f0f;
              width: 150px;
            `}
            sm
            prefix={<span className={`flag-icon ${lang.value === 'English' ? 'flag-icon-gb' : 'flag-icon-es'}`} />}
            value={lang}
            onChange={e => setLang(e.target.value)}
            defaultValue="English"
            options={[
              { value: 'English', label: 'English' },
              { value: 'Spanish', label: 'Spanish' },
            ]}
          />
          <ModalContainer
            lg
            btnComponent={({ onClick }) => (
              <a href="#" onClick={onClick}>
                Contact Us
              </a>
            )}
            content={({ onClose }) => <ContactUs onClose={onClose} />}
            title={'Advanced Data Analytics for Your Business'}
          />
          {showDemo && (
            <DemoButton
              onClick={() => {
                window.location.href = window.location.href.includes('.co.uk')
                  ? process.env.REACT_APP_ADMIN_URL.replace('.com', '.co.uk')
                  : process.env.REACT_APP_ADMIN_URL;
              }}>
              DEMO
            </DemoButton>
          )}
        </NavWrapper>
      </NavBar>

      <Title>{title}</Title>
    </HeaderContainer>
  );
};

export default HeaderComponent;
