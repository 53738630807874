import styled from 'styled-components/macro';
export const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 1.5rem;
  background-image: url('https://s3.amazonaws.com/s3-biz4intellia/images/tab-banner.jpg');
  background-size: cover;
  background-attachment: fixed;
  padding: 40px 0 70px;
`;

export const TabletWrapper = styled.div`
  width: 50%;
  text-align: center;
  position: relative;
`;

export const TabletImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Slideshow = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  overflow: hidden;
`;

export const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute; /* Ensure images are stacked */
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.8s ease-in-out;
  z-index: ${props => (props.active ? 1 : 0)};
`;

export const TextWrapper = styled.div`
  width: 50%;
  padding-left: 2rem;
  color: #fff;
`;

export const Subheading = styled.h4`
  font-size: 1rem;
  color: #007bff;
  color: #fff;
`;

export const AnimatedHeading = styled.h2`
  font-size: 2rem;
  color: #2c3e50;
  margin: 0.5rem 0;
  transition: all 0.8s ease-in-out;
  color: #fff;
`;

export const Description = styled.p`
  font-size: 1.125rem;
  line-height: 1.8;
  color: #fff;
`;
