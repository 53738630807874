import styled from 'styled-components';

// Container for the entire pricing section

export const PriceHolder = styled.div`
  align-items: center;
  -moz-column-gap: 8px;
  column-gap: 8px;
  display: flex;
  margin-bottom: 16px;
`;
export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
`;

// Styled card for each pricing plan
export const PricingCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 350px;
  text-align: center;
  transition: all 0.3s ease;
  background: #fff;
  border-radius: 28px;
  outline: 1px solid #e3e7ee;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

// Header for each plan
export const PlanHeader = styled.h3`
  font-size: 1.2rem;
  color: #343a40;
  font-weight: 600;
`;

// Price styling
export const Price = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem 0;
`;
export const PriceSave = styled.div`
  border: 1px solid #ade28c;
  border-radius: 5px;
  color: #5bc51a;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
  line-height: 1.3;
  padding: 3px 8px;
`;

// List of features for the plan
export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  margin-top: 1rem;
`;

export const FeatureItem = styled.li`
  padding: 0.5rem 0;
  font-size: 1rem;
`;

// Styled Action Button
export const ActionButton = styled.button`
  background-color: #5e60ce;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 0.8rem;
  min-width: 200px;

  &:hover {
    background-color: #4c52b2;
  }
`;

export const PricingBlockSwitcherInner = styled.div`
  align-items: center;
  background-color: #e5e8ec;
  border-radius: 12px;
  column-gap: 5px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 327px;
  padding: 5px;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 680px) {
    flex-direction: row;
    max-width: max-content;
    min-width: auto;
  }
`;

export const PricingBlockSwitcherLabel = styled.div`
  background-color: #fff;
  border-radius: 7px;
  box-shadow: inset 0 -1px 0 0 rgba(25, 36, 53, 0.2);
  filter: drop-shadow(0 1px 2px rgba(31, 40, 44, 0.12));
  height: 40px;
  position: absolute;
  transition: transform 0.2s ease-out;
  z-index: 1;
  transform: ${({ translateX }) => `translateX(${translateX}px)`};
  width: ${({ width }) => width}px;
`;

export const PricingBlockSwitcherTab = styled.div`
  align-items: center;
  color: #2e2151;
  column-gap: 12px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  letter-spacing: -0.32px;
  line-height: 1.5;
  padding: 8px 12px;
  position: relative;
  transition: font-weight 0.13s cubic-bezier(0.4, 0, 1, 1);
  white-space: nowrap;
  width: 100%;
  z-index: 2;

  &.active {
    font-weight: 700;
  }

  @media only screen and (min-width: 680px) {
    padding-left: 21px;
    padding-right: 21px;
    width: auto;
  }
`;

export const AnnualTab = styled(PricingBlockSwitcherTab)`
  padding: 5px 12px;

  @media only screen and (min-width: 680px) {
    padding-left: 12px;
    padding-right: 5px;
  }
`;

export const PricingBlockSwitcherDiscount = styled.div`
  background: linear-gradient(90deg, #ffad31, #ff3187);
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 8px;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }
`;
