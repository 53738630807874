import Label from 'components/atoms/Label';
import { StyledPhoneInput, InputHolder, Error } from './PhoneInput.styles';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect, useState } from 'react';

const PhoneInput = ({ name, label, value, rules, onChange, required, error, ...rest }) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (value) {
      handleInputChange(value);
    }
  }, [value]);

  const handleInputChange = number => {
    if (number) {
      setIsValid(isValidPhoneNumber(number));
      onChange({ target: { name: name, value: number } });
    } else {
      onChange({ target: { name: name, value: '' } });
    }
  };
  const inputProps = {
    id: rest.id ?? name,
    error: !isValid || error,
    name,
    'aria-describedby': `${name}Error`,
    ...rest,
  };
  return (
    <InputHolder>
      <Label required={rules?.filter(({ required }) => required).length}>{label}</Label>

      <StyledPhoneInput
        {...inputProps}
        value={value}
        sm
        valid={isValid}
        placeholder="Enter phone number"
        onChange={handleInputChange}
      />
      {(!isValid || error) && <Error>Invalid Phone Number</Error>}
    </InputHolder>
  );
};
export default PhoneInput;
