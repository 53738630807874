import styled from 'styled-components/macro';

export const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  height: 470px;
  background-image: url('https://s3.amazonaws.com/s3-biz4intellia/images/banner-img.jpg'); /* Use actual image path */
  background-size: cover;
  background-position: bottom center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 0 1.5rem;
    height: 350px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
  }
`;

export const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #000; /* Replace with your logo color */
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  img {
    height: 150px;
    transition: height 0.3s ease-in-out;

    @media (max-width: 768px) {
      height: 40px;
    }

    @media (max-width: 480px) {
      height: 35px;
    }
  }
`;

export const DemoButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #39c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.4rem 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.85rem;
    padding: 0.35rem 0.8rem;
  }
`;

export const Title = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  transition: font-size 0.3s ease, top 0.3s ease;
  text-align: center;
  font-size: 34px;
  color: #253c58;
  font-weight: 700;
  text-shadow: 2px 2px 2px #69f;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
    top: 60%;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15rem;
`;
export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
