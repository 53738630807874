import React from 'react';

export default function Ranking() {
  return (
    <div className="ranking-section">
      <div className="container">
        <div className="heading">
          <h3>Awards &amp; Recognition</h3>
          <p>
            Our services has been recognized and acknowledged. See what some of the leading platforms thinks about us.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="client-partner-slider wow fadeInLeft slick-initialized slick-slider slick-dotted"
              style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
              <div className="slick-list draggable" style={{ padding: '0px' }}>
                <div
                  className="slick-track"
                  style={{ opacity: '1', width: '5415px', transform: 'translate3d(-584px, 0px, 0px)' }}>
                  <div
                    className="slick-slide slick-cloned"
                    data-slick-index="-5"
                    aria-hidden="true"
                    style={{ width: '285px' }}
                    tabindex="-1">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://www.sortlist.com/agency/biz4intellia"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="-1">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-blue-light-xl.svg"
                                alt="SortList"
                                style={{ width: '54% !important', marginTop: '83px' }}
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>SortList</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-cloned"
                    data-slick-index="-4"
                    aria-hidden="true"
                    style={{ width: '285px' }}
                    tabindex="-1">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://topsoftwarecompanies.co/united-states-of-america/iot-development/agencies/florida"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="-1">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://topsoftwarecompanies.co/badges/top-software-developers.png"
                                alt="Top Software Companies"
                                style={{ marginTop: '60px', width: '60% !important' }}
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>Top Software Companies</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-cloned"
                    data-slick-index="-3"
                    aria-hidden="true"
                    style={{ width: '285px' }}
                    tabindex="-1">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://topwebdevelopmentcompanies.com/us/top-10/iot-development/orlando"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="-1">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://topwebdevelopmentcompanies.com/badges/top-app-development-companies.png"
                                alt="iot developers orlando"
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>Top Web Development Companies</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-cloned slick-active"
                    data-slick-index="-1"
                    aria-hidden="false"
                    style={{ width: '285px' }}
                    tabindex="-1">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://www.itfirms.co/top-iot-app-development-companies"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="0">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://s3-biz4intellia.s3.amazonaws.com/images/itfirms-top-iot-solution-providers-badge-2023.png"
                                alt="itfirms-top-iot-solution-providers-badge-2023"
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>IT Firms</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-current slick-active slick-center"
                    data-slick-index="0"
                    aria-hidden="false"
                    style={{ width: '285px' }}
                    role="tabpanel"
                    id="slick-slide10">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://www.mobileappdaily.com/directory/iot-app-development-companies"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="0">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://s3-biz4intellia.s3.amazonaws.com/images/top-app-developers.jpg"
                                alt="Top App Developers"
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>Top App Developers</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-active"
                    data-slick-index="1"
                    aria-hidden="false"
                    style={{ width: '285px' }}
                    role="tabpanel"
                    id="slick-slide11">
                    <div>
                      <div className="item" style={{ width: '100%', display: 'inlineBlock' }}>
                        <a
                          href="https://www.c2creview.co/company/mstcontrol"
                          className="ranking-batch-link"
                          target="_blank"
                          tabindex="0">
                          <div className="ranking-batch">
                            <div className="ranking-batch-img">
                              <img
                                src="https://c2creview.co/uploads/badges/1610037497_badge-service02.jpg"
                                alt="C2CReview"
                                width="640"
                                height="320"
                                loading="lazy"
                              />
                            </div>
                            <div className="ranking-batch-border text-center">
                              <span></span>
                            </div>
                            <div className="ranking-batch-title">
                              <p>C2CReview</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
