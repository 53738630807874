import HeaderComponent from 'components/organisms/header';
import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0077b6;
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #023e8a;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  margin: 5px 0;
  &:before {
    content: '• ';
    color: #0077b6;
  }
`;

const PrivacyPolicy = () => {
  return (
    <div className="start-text">
      <HeaderComponent title="Privacy Policy for MST Control" />
      <link
        rel="preload"
        href="https://www.biz4intellia.com/fonts/fontawesome-webfont.woff2?v=4.7.0"
        as="font"
        crossorigin=""
      />
      <PrivacyContainer>
        <Paragraph>Last updated: September 30, 2024</Paragraph>

        <Section>
          <Subtitle>Privacy Policy</Subtitle>
          <Paragraph>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
            information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </Paragraph>
          <Paragraph>
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
            collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been
            created with the help of the Free Privacy Policy Generator.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Interpretation and Definitions</Subtitle>
          <Subtitle>Interpretation</Subtitle>
          <Paragraph>
            The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.
          </Paragraph>
          <Subtitle>Definitions</Subtitle>
          <Paragraph>For the purposes of this Privacy Policy:</Paragraph>
          <List>
            <ListItem>
              <strong>Account</strong> means a unique account created for You to access our Service or parts of our
              Service.
            </ListItem>
            <ListItem>
              <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with
              a party.
            </ListItem>
            <ListItem>
              <strong>Company</strong> refers to MST Technology Ltd, 25a Dorville Crescent.
            </ListItem>
            <ListItem>
              <strong>Cookies</strong> are small files placed on Your computer or mobile device.
            </ListItem>
            <ListItem>
              <strong>Country</strong> refers to the United Kingdom.
            </ListItem>
            <ListItem>
              <strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or
              tablet.
            </ListItem>
            <ListItem>
              <strong>Personal Data</strong> is any information that relates to an identified or identifiable
              individual.
            </ListItem>
            <ListItem>
              <strong>Service</strong> refers to the Website.
            </ListItem>
            <ListItem>
              <strong>Service Provider</strong> means any natural or legal person who processes data on behalf of the
              Company.
            </ListItem>
            <ListItem>
              <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
              Service or from the Service infrastructure.
            </ListItem>
            <ListItem>
              <strong>Website</strong> refers to MST Control, accessible from mstcontrol.co.uk.
            </ListItem>
            <ListItem>
              <strong>You</strong> means the individual accessing or using the Service or the legal entity on behalf of
              which such individual is using the Service.
            </ListItem>
          </List>
        </Section>

        <Section>
          <Subtitle>Collecting and Using Your Personal Data</Subtitle>
          <Subtitle>Types of Data Collected</Subtitle>
          <Paragraph>
            <strong>Personal Data</strong>
          </Paragraph>
          <Paragraph>
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:
          </Paragraph>
          <List>
            <ListItem>Email address</ListItem>
            <ListItem>First name and last name</ListItem>
            <ListItem>Phone number</ListItem>
            <ListItem>Address, State, Province, ZIP/Postal code, City</ListItem>
          </List>

          <Subtitle>Usage Data</Subtitle>
          <Paragraph>
            Usage Data is collected automatically when using the Service. Usage Data may include information such as
            Your Device's IP address, browser type, browser version, the pages of our Service that You visit, the time
            and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Tracking Technologies and Cookies</Subtitle>
          <Paragraph>
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
            to improve and analyze Our Service.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Use of Your Personal Data</Subtitle>
          <Paragraph>The Company may use Personal Data for the following purposes:</Paragraph>
          <List>
            <ListItem>To provide and maintain our Service.</ListItem>
            <ListItem>To manage Your Account.</ListItem>
            <ListItem>To contact You.</ListItem>
            <ListItem>To manage Your requests.</ListItem>
          </List>
        </Section>

        <Section>
          <Subtitle>Retention of Your Personal Data</Subtitle>
          <Paragraph>
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Transfer of Your Personal Data</Subtitle>
          <Paragraph>
            Your information, including Personal Data, may be transferred to — and maintained on — computers located
            outside of Your state or country.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Disclosure of Your Personal Data</Subtitle>
          <Paragraph>
            <strong>Business Transactions:</strong> If the Company is involved in a merger, Your Personal Data may be
            transferred.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Security of Your Personal Data</Subtitle>
          <Paragraph>
            The security of Your Personal Data is important to Us, but no method of transmission over the Internet is
            100% secure.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Children's Privacy</Subtitle>
          <Paragraph>
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from children.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Links to Other Websites</Subtitle>
          <Paragraph>Our Service may contain links to other websites that are not operated by Us.</Paragraph>
        </Section>

        <Section>
          <Subtitle>Changes to this Privacy Policy</Subtitle>
          <Paragraph>
            We may update Our Privacy Policy from time to time and notify you of changes via email or prominent notice
            on our service.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Contact Us</Subtitle>
          <Paragraph>
            If you have any questions about this Privacy Policy, you can contact us by email: hello@mstcontrol.com.
          </Paragraph>
        </Section>
      </PrivacyContainer>
    </div>
  );
};

export default PrivacyPolicy;
