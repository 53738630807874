import React from 'react';
import _1 from 'assets/images/1.png';
import _2 from 'assets/images/2.png';
import _5 from 'assets/images/5.png';
import _4 from 'assets/images/4.png';
import _3 from 'assets/images/3.png';
import _6 from 'assets/images/6.png';
import _7 from 'assets/images/7.png';
import _8 from 'assets/images/8.png';
import _9 from 'assets/images/9.png';
import _10 from 'assets/images/10.png';
import {
  SectionContainer,
  TabletWrapper,
  TabletImage,
  Slideshow,
  SlideImage,
  TextWrapper,
  Subheading,
  AnimatedHeading,
  Description,
} from './tabtext.styles';
import { useEffect, useState } from 'react';
import 'styled-components/macro';
import { Fade } from 'react-awesome-reveal';

const images = [_1, _2, _5, _4, _3, _6, _7, _8, _9, _10];

const headings = ['Advanced Analytics', 'User Friendly Dashboard', 'Live Machine Monitoring'];

const SlideshowSection = () => {
  const [activeImage, setActiveImage] = useState(0);
  const [activeHeading, setActiveHeading] = useState(0);
  const [displayedHeading, setDisplayedHeading] = useState(''); // For typing effect
  const typingSpeed = 100;

  useEffect(() => {
    let charIndex = -1;
    setDisplayedHeading('');

    const typeHeading = () => {
      if (charIndex < headings[activeHeading].length) {
        setDisplayedHeading(prev => prev + headings[activeHeading].charAt(charIndex));
        charIndex++;
        setTimeout(typeHeading, typingSpeed);
      } else {
        setTimeout(() => {
          setActiveHeading(prev => (prev + 1) % headings.length);
        }, 1000);
      }
    };

    setTimeout(typeHeading, 500);
  }, [activeHeading]);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setActiveImage(prev => {
        return (prev + 1) % images.length;
      });
    }, 2000);

    return () => clearInterval(imageInterval);
  }, []);

  return (
    <SectionContainer>
      <TabletWrapper>
        <TabletImage src="https://s3.amazonaws.com/s3-biz4intellia/images/tab-img.png" alt="Tablet" />
        <Slideshow>
          {images.map((image, index) => (
            <SlideImage key={index} src={image} active={index === activeImage} alt={`Slide ${index}`} />
          ))}
        </Slideshow>
      </TabletWrapper>

      <TextWrapper>
        <Subheading>IOT AT YOUR FINGERTIPS</Subheading>
        <Fade direction="down" triggerOnce>
          <AnimatedHeading>{displayedHeading}</AnimatedHeading>
        </Fade>
        <Description>
          MST Control, at the forefront of the IoT industry, not only delivers innovative products but also ensures that
          all IoT needs are met with tailored solutions. Our systems are designed for quick implementation, typically
          within 1-2 weeks, providing powerful analytics and live data monitoring to empower your business decisions.
        </Description>
      </TextWrapper>
    </SectionContainer>
  );
};

export default SlideshowSection;
