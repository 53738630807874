import HeaderComponent from 'components/organisms/header';
import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0077b6;
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #023e8a;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  margin: 5px 0;
  &:before {
    content: '• ';
    color: #0077b6;
  }
`;

const TermsOfService = () => {
  return (
    <div className="start-text">
      <HeaderComponent title="Terms of Service for MST Control" />
      <link
        rel="preload"
        href="https://www.biz4intellia.com/fonts/fontawesome-webfont.woff2?v=4.7.0"
        as="font"
        crossorigin=""
      />
      <TermsContainer>
        <Paragraph>Last updated: September 30, 2024</Paragraph>

        <Section>
          <Subtitle>Introduction</Subtitle>
          <Paragraph>
            These Terms of Service govern your use of our website located at mstcontrol.co.uk operated by MST Technology
            Ltd.
          </Paragraph>
          <Paragraph>
            Our Terms and Conditions are meant to ensure that both parties are aware of and agree upon these terms to
            mutual benefit and protection.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Acceptance of Terms</Subtitle>
          <Paragraph>
            By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of
            these Terms, you must not access or use our Service.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Accounts</Subtitle>
          <Paragraph>
            When you create an account with us, you must provide us with accurate and up-to-date information. Failure to
            do so constitutes a breach of the Terms, which may result in the termination of your account.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Intellectual Property</Subtitle>
          <Paragraph>
            The Service and its original content, features, and functionality are and will remain the exclusive property
            of MST Technology Ltd and its licensors. Our trademarks and trade dress may not be used in connection with
            any product or service without the prior written consent of MST Technology Ltd.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Termination</Subtitle>
          <Paragraph>
            We may terminate or suspend your access to our Service immediately, without prior notice or liability, for
            any reason whatsoever, including without limitation if you breach the Terms.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Limitation of Liability</Subtitle>
          <Paragraph>
            To the maximum extent permitted by applicable law, in no event shall MST Technology Ltd, nor its directors,
            employees, partners, or agents, be liable for any indirect, incidental, special, consequential, or punitive
            damages, including without limitation, loss of profits, data, or other intangible losses, resulting from
            your use of the Service.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Governing Law</Subtitle>
          <Paragraph>
            These Terms shall be governed and construed in accordance with the laws of the United Kingdom, without
            regard to its conflict of law provisions.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Changes to These Terms</Subtitle>
          <Paragraph>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
            material, we will provide at least 30 days' notice prior to any new terms taking effect.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Contact Us</Subtitle>
          <Paragraph>If you have any questions about these Terms, please contact us:</Paragraph>
          <List>
            <ListItem>By email: hello@mstcontrol.com</ListItem>
          </List>
        </Section>
      </TermsContainer>
    </div>
  );
};

export default TermsOfService;
