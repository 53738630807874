import React from 'react';
import 'styled-components/macro';

export default function BeforeFooter() {
  return (
    <div
      className="before-footer-section lazy1"
      css={`
        margin-top: 20px;
        background-image: url('https://s3.amazonaws.com/s3-biz4intellia/images/before-footer.jpg');
        padding: 50px 0;
      `}>
      <div className="before-footer-section-m">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-8">
              <h2>Begin Your MST Journey</h2>
              <p>
                Select the plan that best suits your needs and start your journey with us. We are here to help you at
                every step.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
