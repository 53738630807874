import React from 'react';
import moment from 'moment-timezone';
import { TimeZone } from './DateModal.styles';
import Select from 'react-select';

const TimezoneList = ({ selectedTimezone, onTimezoneChange }) => {
  const timezones = moment.tz.names();

  const formatTimezone = timezone => {
    const zone = moment.tz.zone(timezone);
    const offset = zone.utcOffset(moment.utc());
    const hours = Math.floor(offset / 60);
    const minutes = offset % 60;
    const formattedOffset = `(UTC${hours >= 0 ? '+' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes})`;
    return `${formattedOffset} ${timezone}`;
  };

  const options = timezones.map(timezone => ({
    value: timezone,
    label: formatTimezone(timezone),
  }));

  return (
    <TimeZone>
      <h1
        style={{
          color: 'var(--secondary, #434956)',
          fontFamily: 'Outfit',
          fontSize: '18px',
          fontWeight: '600',
          lineHeight: 'normal',
          textAlign: 'left',
        }}>
        Time Zone
      </h1>
      <Select
        value={options.find(option => option.value === selectedTimezone)}
        onChange={option => onTimezoneChange(option.value)}
        options={options}
        className="select-dropdown"
        isSearchable
      />
    </TimeZone>
  );
};

export default TimezoneList;
