import styled from 'styled-components';

// Container for the whole midsection
export const MidSectionContainer = styled.section`
  padding: 3rem 1.5rem;
  background-color: #f9f9f9;
  text-align: center;
`;

// Wrapper for the description text
export const DescriptionWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
`;

// Heading style
export const SectionHeading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1.5rem;
`;

// Description text
export const DescriptionText = styled.p`
  font-size: 1.125rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
`;

// Emphasized bold text for features
export const BoldText = styled.strong`
  font-weight: bold;
  color: #2c3e50;
`;

// Technology partners section
export const PartnersSection = styled.div`
  margin-top: 3rem;
`;

// Partners heading
export const PartnersHeading = styled.h3`
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 2rem;
`;

// Partner logos container
export const PartnersGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

// Partner logo item
export const PartnerLogo = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 80px;
  }
`;
