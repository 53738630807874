import React from 'react';
import ocean from 'assets/images/ocean.webp';
import mongo from 'assets/images/mongo.jpeg';
import googlecloud from 'assets/images/googlecloud.png';
import nodejs from 'assets/images/nodejs.svg';
import {
  MidSectionContainer,
  DescriptionWrapper,
  SectionHeading,
  DescriptionText,
  BoldText,
  PartnersSection,
  PartnersHeading,
  PartnersGrid,
  PartnerLogo,
} from './aboutus.styles';
import { Fade } from 'react-awesome-reveal';

const MidSectionComponent = () => {
  return (
    <MidSectionContainer>
      <Fade direction="down" triggerOnce>
        <SectionHeading>MST Control (Your Real-Time Data Monitoring Hub)</SectionHeading>
      </Fade>

      <DescriptionWrapper>
        <Fade direction="right" triggerOnce>
          <DescriptionText>
            MST Control offers a comprehensive dashboard for real-time and historical data analysis across multiple
            timeframes, tailored for diverse industrial needs. This solution is at the forefront of Industrial IoT
            (Internet of Things), enabling robust data monitoring and management.
          </DescriptionText>
        </Fade>

        <Fade direction="right" triggerOnce>
          <DescriptionText>
            <BoldText>Real-Time Dashboard</BoldText> provides live data visualization, leveraging direct feeds from IoT
            devices. Our advanced Go language servers process this data swiftly, ensuring that you have access to the
            latest information without delay.
          </DescriptionText>
        </Fade>

        <Fade direction="right" triggerOnce>
          <DescriptionText>
            <BoldText>Data Management and Security</BoldText> are at the core of our services. We store all incoming
            data on our secure servers, which are designed to handle large volumes of IoT data with utmost security and
            reliability.
          </DescriptionText>
        </Fade>

        <Fade direction="right" triggerOnce>
          <DescriptionText>
            Our system includes a sophisticated <BoldText>Statistical Report Generation</BoldText> tool that
            automatically compiles detailed reports on machine performance. This enables our clients to make informed
            decisions based on comprehensive analytics and trends. MST Control is designed to be highly scalable,
            supporting a range of industries such as Transportation, Water Management, and Healthcare—ideal for
            businesses aiming for smart, data-driven operations.
          </DescriptionText>
        </Fade>
      </DescriptionWrapper>

      <PartnersSection>
        <PartnersHeading>Technology Partners</PartnersHeading>
        <PartnersGrid>
          <Fade direction="left" triggerOnce>
            <PartnerLogo>
              <img src={ocean} alt="Digital Ocean Partner" />
            </PartnerLogo>
          </Fade>
          <Fade direction="left" triggerOnce>
            <PartnerLogo>
              <img src={googlecloud} alt="Google Cloud Partner" />
            </PartnerLogo>
          </Fade>
          <Fade direction="left" triggerOnce>
            <PartnerLogo>
              <img src={mongo} alt="MongoDB Partner" />
            </PartnerLogo>
          </Fade>
          <Fade direction="left" triggerOnce>
            <PartnerLogo>
              <img src={nodejs} alt="NodeJS" />
            </PartnerLogo>
          </Fade>
        </PartnersGrid>
      </PartnersSection>
    </MidSectionContainer>
  );
};

export default MidSectionComponent;

// export default function AboutUs() {
//   return (
//     <div className="about-us wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
//       <div className="container">
//         <div className="row">
//           <div
//             className="col-md-12 col-sm-12 wow fadeInRight pull-right"
//             style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
//             <div className="abt-content">
//               <h2>MST Control (Your Real-Time Data Monitoring Hub)</h2>
//               <p>
//                 MST Control offers a comprehensive dashboard for real-time and historical data analysis across multiple
//                 timeframes, tailored for diverse industrial needs. This solution is at the forefront of Industrial IoT
//                 (Internet of Things), enabling robust data monitoring and management.
//               </p>
//               <p>
//                 <b>Real-Time Dashboard</b> provides live data visualization, leveraging direct feeds from IoT devices.
//                 Our advanced Go language servers process this data swiftly, ensuring that you have access to the latest
//                 information without delay.
//               </p>
//               <p>
//                 <b>Data Management and Security</b> are at the core of our services. We store all incoming data on our
//                 secure servers, which are designed to handle large volumes of IoT data with utmost security and
//                 reliability.
//               </p>
//               <p>
//                 Our system includes a sophisticated <b>Statistical Report Generation</b> tool that automatically
//                 compiles detailed reports on machine performance. This enables our clients to make informed decisions
//                 based on comprehensive analytics and trends. MST Control is designed to be highly scalable, supporting a
//                 range of industries such as Transportation, Water Management, and Healthcare—ideal for businesses aiming
//                 for smart, data-driven operations.
//               </p>
//             </div>
//           </div>
//           <div
//             className="col-md-12 col-sm-12 wow fadeInLeft pull-left"
//             style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
//             <div className="client-partner-section">
//               <h4>Technology Partners</h4>
//               <div className="client-partner-logos">
//                 <ul>
//                   <li>
//                     <div className="client-logo-img">
//                       <img src={ocean} alt="" />
//                     </div>
//                   </li>
//                   <li>
//                     <div className="client-logo-img">
//                       <img src={mongo} alt="" />
//                     </div>
//                   </li>
//                   <li>
//                     <div className="client-logo-img">
//                       <img src={googlecloud} alt="" />
//                     </div>
//                   </li>
//                   <li>
//                     <div className="client-logo-img">
//                       <img src={nodejs} alt="" />
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
