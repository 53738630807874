/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useEffect } from 'react';
import { createContextHook } from 'use-context-hook';

const context = {};

export const LoadingContext = createContextHook(context);

export function LoadingContextProvider(props) {
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState({ label: 'English', value: 'English' });

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // Cancel the timer while unmounting
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        isLoading: loading,
        lang,
        setLang,
      }}>
      {props.children}
    </LoadingContext.Provider>
  );
}
