import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { LoadingContextProvider } from './context/loadingContext';
import GlobalStyles from './styles/GlobalStyles.styles';
import { StyledToastContainer } from './styles/App.styles';

import { theme } from './config/theme';
import HomePage from './pages/homepage';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsOfService from './pages/termsOfService';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingContextProvider>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </BrowserRouter>
        <StyledToastContainer />
      </LoadingContextProvider>
    </ThemeProvider>
  );
}

export default App;
