import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ModalContainer from 'components/molecules/ModalContainer';
import ContactUs from '../contact-us';

const FooterContainer = styled.footer`
  background-color: #122c4e;
  color: white;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterLinks = styled.div`
  margin-bottom: 15px;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1rem;

  &:hover {
    color: #00b4d8;
    text-decoration: underline;
  }
`;

const CopyrightText = styled.p`
  margin: 0;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
        <ModalContainer
          lg
          btnComponent={({ onClick }) => (
            <FooterLink
              to={'#'}
              onClick={e => {
                e.preventDefault();
                onClick();
              }}>
              Contact Us
            </FooterLink>
          )}
          content={({ onClose }) => <ContactUs onClose={onClose} />}
          title={'Advanced Data Analytics for Your Business'}
        />
      </FooterLinks>
      <CopyrightText>&copy; {new Date().getFullYear()} MST Control. All Rights Reserved.</CopyrightText>
    </FooterContainer>
  );
};

export default Footer;
