import { darken, cssVar } from 'polished';
import styled from 'styled-components/macro';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const StyledPhoneInput = styled(PhoneInput)`
  border: 2px solid ${({ valid }) => (!valid ? 'var(--danger)' : 'var(--light)')};
  background: var(--white);
  outline: none;
  height: ${({ sm }) => (sm ? '45px' : '50px')};
  padding: ${({ sm }) => (sm ? '0.3125rem .9375rem' : 'var(--form-element-padding)')};
  width: 100%;
  display: flex;
  align-items: center;
  transition: border var(--animation-speed) ease-in-out;
  color: var(--secondary-text-color);
  font-size: var(--font-size-sm);
  border-radius: 12px;
  border-color: ${({ valid, value_exists }) => valid && value_exists && `${darken(0.1, cssVar('--primary'))}`};

  .PhoneInputInput {
    border: none;
    &:focus {
      outline: none;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--secondary-text-color);
    opacity: 0.6;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--secondary-text-color);
    opacity: 0.6;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--secondary-text-color);
    opacity: 0.6;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--secondary-text-color);
    opacity: 0.6;
  }
  ${({ error }) =>
    error &&
    `
    border-color: var(--danger);
  `}
`;
export const InputHolder = styled.div`
  margin-bottom: 1.625rem;
`;

export const Error = styled.span`
  display: block;
  color: var(--danger);
  min-height: 26px;
  height: auto;
  opacity: 1;
  font-size: var(--font-size-xs);
  line-height: calc(var(--font-size-xs) + 0.25rem);
  padding-top: 3px;
  &:first-letter {
    text-transform: uppercase;
  }
`;
