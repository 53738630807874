import React from 'react';

export default function Industries() {
  return (
    <div
      className="industries-m wow fadeInDown lazy1"
      style={{
        visibility: 'visible',
        animationName: 'fadeInDown',
        backgroundImage: "url('https://s3.amazonaws.com/s3-biz4intellia/images/industry-bg2.jpg')",
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
      }}>
      <div className="container">
        "
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h3>Industries We Serve</h3>
            </div>
            <div className="industries-tab">
              <ul className="nav nav-tabs">
                <li
                  className="wow fadeInLeft"
                  data-wow-delay="0.2s"
                  style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft' }}>
                  <a data-toggle="tab" href="#industry2">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/industry-icon2.png"
                      alt="Transportation"
                    />
                    Transportation
                  </a>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-delay="0.3s"
                  style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInLeft' }}>
                  <a data-toggle="tab" href="#industry3">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/industry-icon3.png"
                      alt="Mining"
                    />
                    Mining
                  </a>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-delay="0.4s"
                  style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInLeft' }}>
                  <a data-toggle="tab" href="#industry4">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/industry-icon4.png"
                      alt="Healthcare"
                    />
                    Healthcare
                  </a>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-delay="0.5s"
                  style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInLeft' }}>
                  <a data-toggle="tab" href="#industry5">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/industry-icon5.png"
                      alt="Water"
                    />
                    Water
                  </a>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-delay="0.6s"
                  style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInLeft' }}>
                  <a data-toggle="tab" href="#industry6">
                    <img
                      className="lazy1"
                      src="https://s3.amazonaws.com/s3-biz4intellia/images/industry-icon6.png"
                      alt="Hospitality"
                    />
                    Hospitality
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="industry2" className="tab-pane fade in active">
                  <h4>Transportation and Logistics Industry</h4>
                  <p>
                    Optimizing route planning and fleet management with real-time data insights, reducing operational
                    costs and improving service delivery.
                  </p>
                </div>
                <div id="industry3" className="tab-pane fade">
                  <h4>Mining Industry</h4>
                  <p>
                    Improving safety and efficiency through continuous monitoring and predictive analytics, enabling
                    proactive maintenance and operations.
                  </p>
                </div>
                <div id="industry4" className="tab-pane fade">
                  <h4>Healthcare Industry</h4>
                  <p>
                    Advancing patient care with data-driven solutions that enhance diagnostic accuracy, patient
                    monitoring, and operational efficiency.
                  </p>
                </div>
                <div id="industry5" className="tab-pane fade">
                  <h4>Water Industry</h4>
                  <p>
                    Ensuring sustainable water management through intelligent IoT solutions that predict demand, monitor
                    resources, and optimize distribution.
                  </p>
                </div>
                <div id="industry6" className="tab-pane fade">
                  <h4>Hospitality Industry</h4>
                  <p>
                    Enhancing guest experiences through smart technology integration, optimizing operational management,
                    and personalizing services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
