/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Header from 'components/organisms/header';
import AboutUs from 'components/organisms/about-us';
import TabText from 'components/organisms/tab-text';
import Services from 'components/organisms/services';
import WhyChooseUs from 'components/organisms/why-choose-us';
import ConnectSection from 'components/organisms/connect-section';
import Industries from 'components/organisms/industries';
import WordDetails from 'components/organisms/work-details';
import Ranking from 'components/organisms/ranking-section';
import BeforeFooter from 'components/organisms/before-footer-section';
import Offers from 'components/organisms/offers';
import Footer from 'components/organisms/footer';

export default function Homepage() {
  return (
    <>
      <div className="start-text">
        <link
          rel="preload"
          href="https://www.biz4intellia.com/fonts/fontawesome-webfont.woff2?v=4.7.0"
          as="font"
          crossorigin=""
        />
        <Header showDemo />
        <AboutUs />
        <TabText />
        <Services />
        <WhyChooseUs />
        <ConnectSection />
        <Industries />
        <WordDetails />
        <Offers />
        <Ranking />
        <BeforeFooter />
        <Footer />
      </div>
    </>
  );
}
